import { SVGProps, memo } from "react";

function Flame(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 14" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.642.524a.5.5 0 00-.714-.09 6.494 6.494 0 00-2.36 4.117 5.031 5.031 0 01-1.136-1.144.5.5 0 00-.768-.054 6 6 0 106.79-1.33 4.973 4.973 0 01-1.812-1.5zM8.5 8.5a2.5 2.5 0 11-4.875-.781c.419.31.9.54 1.422.667A3.993 3.993 0 016.33 6.022 2.5 2.5 0 018.5 8.5z"
                fill={props.color || "#FF6B00"}
            />
        </svg>
    );
}

const MemoFlame = memo(Flame);
export default MemoFlame;
