const env = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_VERCEL_ENV;
// const host = env === "production" ? "prod" : "dev";
const API_URL =
    process.env.NEXT_PUBLIC_BASE_URL_HEROKU ||
    (env === "production" ? `https://api-prod.blockbeat.io` : `https://api-dev.blockbeat.io`);

export async function fetchBookmarkedList(jwt: string): Promise<any> {
    const res = await fetch(`${API_URL}/my-bookmark-list`, {
        headers: { jwt },
    });
    if (!res.ok) {
        throw new Error("Failed to fetch data");
    }
    return res.json();
}

export const createNewBookmarkEntry = async ({
    jwt,
    slug,
    url,
}: {
    jwt: string;
    slug: string;
    url: string;
}) => {
    const res = await fetch(`${API_URL}/bookmarks/create-bookmark`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ slug, url }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};

export const deleteBookmark = async ({ jwt, slug }: { jwt: string; slug: string }) => {
    const res = await fetch(`${API_URL}/bookmarks/delete-bookmark`, {
        method: "POST",
        headers: {
            jwt,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ slug }),
    });

    if (!res.ok) {
        throw new Error("Failed to make request");
    }

    return await res.json();
};
