import { SVGProps, memo } from "react";

function LinkedInBare(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 15" fill="none" {...props}>
            <path
                d="M1.022 5.163h3.215v9.674H1.022V5.163zM2.652.5C1.55.5.831 1.222.831 2.17c0 .929.698 1.672 1.777 1.672h.02c1.122 0 1.82-.743 1.82-1.672C4.43 1.222 3.751.5 2.651.5zm9.478 4.436c-1.707 0-2.471.939-2.897 1.597v-1.37H6.017c.042.908 0 9.674 0 9.674h3.216V9.435c0-.29.02-.578.105-.785.233-.578.762-1.176 1.65-1.176 1.164 0 1.629.887 1.629 2.187v5.176h3.215V9.29c0-2.971-1.586-4.354-3.702-4.354z"
                fill={props.color || "#7A869B"}
            />
        </svg>
    );
}

const MemoLinkedInBare = memo(LinkedInBare);
export default MemoLinkedInBare;
