enum ChartTimeRange {
    ONE_DAY = "1D",
    ONE_WEEK = "1W",
    THREE_MONTHS = "3M",
    SIX_MONTHS = "6M",
    ONE_YEAR = "1Y",
    FIVE_YEARS = "5Y",
}

enum ChartIntervals {
    ONE_MIN = "1min",
    MIN_15 = "15min",
    MIN_30 = "30min",
    ONE_HOUR = "1hour",
    ONE_DAY = "1day",
}

export { ChartTimeRange, ChartIntervals };
