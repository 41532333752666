import { Dispatch, SetStateAction, useEffect, useState } from "react";
import MemoCopy from "@assets/SVG/Copy";
import MemoXBare from "@assets/SVG/XBare";
import Modal from "@components/Modal";
import MemoTelegramBare from "@assets/SVG/TelegramBare";
import MemoMail from "@assets/SVG/Mail";
import MemoFacebook from "@assets/SVG/Facebook";
import MemoRedditBare from "@assets/SVG/RedditBare";
import MemoLinkedInBare from "@assets/SVG/LinkedInBare";
import { News } from "@/types/api";
import { CopyToClipboardState } from "react-use/lib/useCopyToClipboard";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
} from "react-share";
import { createShortlink } from "@/services/news";
import { useStytchUser } from "@stytch/nextjs";

export function ShareModal({
    news,
    show,
    setShow,
    value,
    copy,
    setShowAlert,
    setAlertMessage,
}: {
    news: News;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    value: CopyToClipboardState;
    copy: (value: string) => void;
    setShowAlert: Dispatch<SetStateAction<boolean>>;
    setAlertMessage?: Dispatch<SetStateAction<string>>;
}) {
    const [shareableLink, setShareableLink] = useState<string>("");

    const user = useStytchUser();

    useEffect(() => {
        if (show) {
            createShortlink({ slug: news.slug, affiliateUserId: user.user?.user_id }).then((res) => {
                setShareableLink(`https://bb.buzz/s/${res.shortlink}`);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal show={show} setShow={setShow} className="overflow-hidden px-0 pb-0 pt-0">
            <div className="flex w-[400px] flex-col items-start gap-3 rounded-lg border-1 border-[#2F343F] bg-[#20242C] px-0 pb-4 pt-0">
                <div className="flex w-full flex-col items-start gap-2 border-y-1 border-[#2F343F] px-4 py-3">
                    <div className="flex items-center justify-between p-0">
                        <h2 className="text-base font-normal leading-5 text-white">Share this article</h2>
                    </div>
                    <p className="text-xs font-light text-[#7A869B]">Select one of the following options:</p>
                </div>
                <div className="flex flex-col items-start gap-3 px-4 py-0">
                    <div className="flex flex-col items-start gap-2 p-0">
                        <div className="flex items-start gap-2 p-0">
                            <TwitterShareButton
                                title="Check this out!"
                                hashtags={["CryptoNews", "BlockBeat"]}
                                url={shareableLink}
                                disabled={!shareableLink}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoXBare className="h-[15px] w-5" />
                                    </div>
                                </div>
                            </TwitterShareButton>
                            <TelegramShareButton
                                title="Check this out!"
                                url={shareableLink}
                                disabled={!shareableLink}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoTelegramBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </TelegramShareButton>
                            <EmailShareButton
                                subject={`${news.headline} - from BlockBeat`}
                                url={""}
                                body={`\n\n${shareableLink}\n\nHey, I thought you would find this interesting:\n\n${
                                    news?.content || news?.headline
                                }`}
                                separator=" "
                                disabled={!shareableLink}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoMail className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </EmailShareButton>
                        </div>
                        <div className="flex items-start gap-2 p-0">
                            <FacebookShareButton
                                quote={"Check this out!"}
                                url={shareableLink}
                                disabled={!shareableLink}
                                // hashtag={"#yoiuuuu"}
                            >
                                {/* undone -  only showing hashtag */}
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoFacebook
                                            className="h-[15px] w-[13px]"
                                            color="#ffffff"
                                            fill="#2F343F"
                                        />
                                    </div>
                                </div>
                            </FacebookShareButton>
                            <RedditShareButton
                                title={`${news.headline} - from BlockBeat`}
                                url={shareableLink}
                                disabled={!shareableLink}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoRedditBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </RedditShareButton>
                            <LinkedinShareButton
                                source="BlockBeat"
                                title="Check this out!"
                                url={shareableLink}
                                disabled={!shareableLink}
                            >
                                <div className="flex w-[117.33px] cursor-pointer flex-col items-center justify-center gap-[10px] rounded-[50px] bg-[#2F343F] px-[9px] py-[10px]">
                                    <div className="flex h-5 w-5 items-center justify-center">
                                        <MemoLinkedInBare className="h-[15px] w-[13px]" color="#FFFFFF" />
                                    </div>
                                </div>
                            </LinkedinShareButton>
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-start gap-1 p-0">
                        <p className="text-xs font-light text-[#7A869B]">Or copy link:</p>
                        <div className="relative flex w-full items-center justify-between gap-2 rounded-lg border-1 border-[#2F343F] p-3">
                            <div className="scrollbar-hide max-w-[19rem] overflow-y-hidden overflow-x-scroll scroll-smooth whitespace-nowrap">
                                <p className="cursor-text text-xs font-light text-white">{shareableLink}</p>
                            </div>
                            {/* <div className="horizontal-scroll-gradient absolute right-[2.45rem] h-4 w-3" /> */}

                            <div
                                onClick={() => {
                                    if (setAlertMessage) {
                                        setAlertMessage("Article link copied!");
                                    }
                                    copy(shareableLink);
                                    setShow(false);
                                    setShowAlert(true);
                                }}
                                className="flex h-4 w-4 cursor-pointer items-center justify-center hover:brightness-75"
                            >
                                <MemoCopy className="h-[11px] w-[11px]" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
