import { useState } from "react";
import { MiniChartTooltip, MiniChartTooltipService } from "../MiniChartTooltip";

export interface SymbolLabelProps {
    Symbol?: string;
    renderedName?: string;
}

export function SymbolLabel({ Symbol = "BTC", renderedName }: SymbolLabelProps) {
    const [symbol] = useState<string>(Symbol);
    const [width] = useState<number>(300);
    const [height] = useState<number>(200);
    const [posX, setPosX] = useState<number>(0);
    const [posY, setPosY] = useState<number>(0);
    const [popupVisible, setPopupVisible] = useState<boolean>(false);

    return (
        <>
            <span
            // onMouseEnter={(e) => {
            //     setPosX(e.pageX);
            //     setPosY(e.pageY);
            //     setPopupVisible(true);
            // }}
            // onMouseLeave={() => {
            //     setPopupVisible(false);
            //     if (MiniChartTooltipService) {
            //         MiniChartTooltipService.setCurrentSymbol("");
            //     }
            // }}
            >
                {renderedName || symbol}
            </span>
            {popupVisible && (
                <>
                    <MiniChartTooltip
                        Visible={popupVisible}
                        Symbol={symbol}
                        Height={height}
                        Width={width}
                        X={posX}
                        Y={posY}
                    ></MiniChartTooltip>
                </>
            )}
        </>
    );
}
