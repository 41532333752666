import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import SentimentGraph from "@assets/SVG/Sentiment/SentimentGraph";

export default function SentimentPopup({ sentimentData, X = 0, Y = 0, width = 227, height = 125 }) {
    const [internalPosY, setInternalPosY] = useState(Y);
    const [internalPosX, setInternalPosX] = useState(X);

    useEffect(() => {
        // const other = document.querySelectorAll<HTMLDivElement>(".sentiment-tooltip");
        // if (other.length > 0) {
        //     other.forEach((e) => {
        //         e.style.display = "none";
        //     });
        // }
        const h = window.getComputedStyle(document.body).getPropertyValue("Height");
        const w = window.getComputedStyle(document.body).getPropertyValue("Width");

        const computedHeight = parseInt(h.substring(0, h.length - 2));
        const computedWidth = parseInt(w.substring(0, w.length - 2));

        const computedPositionY = Y + height > computedHeight ? computedHeight - height - 10 : Y;
        const computedPositionX = X + width > computedWidth ? computedWidth - width - 10 : X;

        setInternalPosY(computedPositionY);
        setInternalPosX(computedPositionX);
    }, []);

    return ReactDOM.createPortal(
        <div
            style={{
                top: internalPosY,
                left: internalPosX,
            }}
            className={`sentiment-tooltip pointer-events-none fixed z-[100000] block rounded-[10px] border-1 border-[#3D4552] bg-[#2F343F] p-2`}
        >
            <p className="mb-2 text-xs font-normal text-white">Sentiment</p>
            <SentimentGraph sentiment={sentimentData} />
            <div className="ml-8 mt-1 flex justify-between gap-1 text-[10px] font-extralight leading-[10px] text-[#959dab]">
                <div className="flex items-center gap-1">
                    <div className="h-1 w-1 bg-[#C83D4D]" />
                    <p>Negative</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-1 w-1 bg-[#697486]" />
                    <p>Neutral</p>
                </div>
                <div className="flex items-center gap-1">
                    <div className="h-1 w-1 bg-[#27AF8F]" />
                    <p>Positive</p>
                </div>
            </div>
        </div>,
        document.getElementsByTagName("body")[0],
        "sentiment-tooltip",
    );
}
