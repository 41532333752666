import { SVGProps, memo } from "react";

function FrowningEmoji(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={5.5} stroke={props.color || "#C83D4D"} />
            <path
                d="M5.6 11h4.8c.247 0 .39-.283.232-.473C10.072 9.845 9.194 9 8 9c-1.194 0-2.072.845-2.632 1.527-.157.19-.015.473.232.473z"
                fill={props.color || "#C83D4D"}
            />
            <circle cx={5.75} cy={6.75} r={0.75} fill={props.color || "#C83D4D"} />
            <circle cx={10.251} cy={6.75} r={0.75} fill={props.color || "#C83D4D"} />
        </svg>
    );
}

const MemoFrowningEmoji = memo(FrowningEmoji);
export default MemoFrowningEmoji;
