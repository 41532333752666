"use client";

import { animated } from "@react-spring/web";
import { SVGProps, memo } from "react";

function Decline(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 8" fill="none" {...props}>
            <path
                d="M6.583 6.917h4.667m0 0V2.25m0 4.667L6.583 2.25 4.25 4.583l-3.5-3.5"
                stroke={props.color || "#C83D4D"}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoDecline = memo(Decline);
const AnimatedDecline = animated(Decline);

export { Decline };
export default MemoDecline;
export { AnimatedDecline };
