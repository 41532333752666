import { SVGProps, memo } from "react";

function NeutralEmoji(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
            <circle cx={8} cy={8} r={5.5} stroke={props.color || "#7A869B"} />
            <circle cx={5.75} cy={6.75} r={0.75} fill={props.color || "#7A869B"} />
            <circle cx={10.251} cy={6.75} r={0.75} fill={props.color || "#7A869B"} />
            <path stroke={props.color || "#7A869B"} strokeLinecap="round" d="M5.5 9.5h5" />
        </svg>
    );
}

const MemoNeutralEmoji = memo(NeutralEmoji);
export default MemoNeutralEmoji;
