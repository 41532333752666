import { SVGProps, memo } from "react";

function TelegramBare(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 10" fill="none" {...props}>
            <path
                d="M.736 4.321s5.307-2.178 7.148-2.945C8.589 1.07 10.982.088 10.982.088s1.105-.43 1.013.613c-.031.43-.277 1.933-.522 3.559-.368 2.3-.767 4.816-.767 4.816s-.061.706-.583.829c-.521.122-1.38-.43-1.534-.553-.122-.092-2.3-1.472-3.098-2.147-.215-.184-.46-.552.03-.982a117.175 117.175 0 003.222-3.068c.368-.368.736-1.227-.798-.184-2.178 1.504-4.326 2.915-4.326 2.915s-.49.307-1.41.03C1.287 5.64.213 5.272.213 5.272s-.736-.46.522-.95z"
                fill={props.color || "#7A869B"}
            />
        </svg>
    );
}

const MemoTelegramBare = memo(TelegramBare);
export default MemoTelegramBare;
