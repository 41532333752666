import "./PercentTag.scss";

export interface PercentTagProps {
    percentChange?: number;
}

export function PercentTag({ percentChange = 1.2 }: PercentTagProps) {
    return (
        <>
            {(percentChange ?? 0) > 0 && (
                <div
                    className={`undefined flex items-center justify-center gap-1 rounded-2xl bg-[#20342F] p-0 px-1.5 text-[#27AF8F]`}
                >
                    <p className={`text-m font-medium`}>
                        {percentChange?.toLocaleString(undefined, {
                            style: "percent",
                            signDisplay: "always",
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 2,
                            minimumIntegerDigits: 1,
                        })}
                    </p>
                    <svg
                        width="1em"
                        height="1em"
                        viewBox="0 0 12 8"
                        fill="none"
                        className="h-3 w-3"
                        color="#27AF8F"
                    >
                        <path
                            d="M6.583 1.083h4.667m0 0V5.75m0-4.667L6.583 5.75 4.25 3.417l-3.5 3.5"
                            stroke="#27AF8F"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </svg>
                </div>
            )}
            {(percentChange ?? 0) < 0 && (
                <div
                    className={`undefined flex items-center justify-center gap-1 rounded-2xl bg-[#372929] p-0 px-1.5 text-[#C83D4D]`}
                >
                    <p className={`text-m font-medium`}>
                        {percentChange?.toLocaleString(undefined, {
                            style: "percent",
                            signDisplay: "always",
                            maximumFractionDigits: 3,
                            minimumFractionDigits: 2,
                            minimumIntegerDigits: 1,
                        })}
                    </p>
                    <svg width="1em" height="1em" viewBox="0 0 12 8" fill="none" className={`h-3 w-3`}>
                        <path
                            d="M6.583 6.917h4.667m0 0V2.25m0 4.667L6.583 2.25 4.25 4.583l-3.5-3.5"
                            stroke="#C83D4D"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        ></path>
                    </svg>
                </div>
            )}
        </>
    );
}
