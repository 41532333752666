"use client";

import { animated } from "@react-spring/web";
import { SVGProps, memo } from "react";

function Growth(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 12 8" fill="none" {...props}>
            <path
                d="M6.583 1.083h4.667m0 0V5.75m0-4.667L6.583 5.75 4.25 3.417l-3.5 3.5"
                stroke={props.color || "#27AF8F"}
                strokeWidth={1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

const MemoGrowth = memo(Growth);
const AnimatedGrowth = animated(Growth);

export { Growth };
export default MemoGrowth;
export { AnimatedGrowth };
