"use client";

import { useEffect, useState } from "react";
import MemoDecline from "@assets/SVG/Decline";
import MemoGrowth from "@assets/SVG/Growth";
import MemoSmilingEmoji from "@assets/SVG/Sentiment/SmilingEmoji";
import MemoFrowningEmoji from "@assets/SVG/Sentiment/FrowningEmoji";
import MemoNeutralEmoji from "@assets/SVG/Sentiment/NeutralEmoji";
import { SymbolLabel } from "@app/TSWidgets/components/SymbolLabel";
import { QuoteService } from "@app/TSWidgets/components/MiniChart";
import { QuoteData } from "@app/TSWidgets/api/TSMarketApi";
import * as signalR from "@microsoft/signalr";
import { fetchSymbolMiniChartData } from "@app/TSWidgets/api/apihelper";
import { ChartIntervals, ChartTimeRange } from "../chart/ChartEnums";

export function PositiveBadge({ asset, from, className = "" }: { asset?; from?; className? }) {
    const [mpchange, setMpChange] = useState(0);
    const quoteService = new QuoteService();

    async function waitUntilStateChangesWithTryCountAndAction(
        tryCount: number,
        stateFunction: () => boolean,
        action: () => Promise<any>,
    ): Promise<any> {
        let count = 0;
        return new Promise<any>((resolve, reject) => {
            const intervalId = setInterval(async () => {
                if (stateFunction()) {
                    clearInterval(intervalId);
                    try {
                        const result = await action();
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    count++;
                    if (count >= tryCount) {
                        clearInterval(intervalId);
                        // reject(new Error("State did not change within the specified try count."));
                        reject("State did not change within the specified try count.");
                    }
                }
            }, 100);
        });
    }

    const initialize = async () => {
        fetchSymbolMiniChartData(asset.symbol, ChartTimeRange.ONE_DAY, ChartIntervals.ONE_MIN).then(
            (data) => {
                const firstPrice = data.data.priceData.findLast((x) => x.date < new Date(from))?.close ?? 0;
                quoteService.getConnection().on("Quote", (r: QuoteData[]) => {
                    const q = r.find((x) => x.s == asset.symbol.toLowerCase());
                    if (q) {
                        setMpChange(((q.lp - firstPrice) / firstPrice) * 100);
                    }
                });
                waitUntilStateChangesWithTryCountAndAction(
                    10,
                    () =>
                        quoteService.getConnection() &&
                        quoteService.getConnection().state == signalR.HubConnectionState.Connected,
                    async () => {
                        quoteService.getConnection().invoke("SubscribeSymbols", [asset.symbol.toLowerCase()]);
                    },
                );
            },
        );
    };
    // let data;
    useEffect(() => {
        initialize();
        return () => {};
    }, [asset.symbol]);

    return (
        <div
            className={`flex items-center justify-center gap-1 rounded-2xl px-2 py-1 transition-all ${
                mpchange > 0
                    ? "bg-[#20342F] text-[#27AF8F]"
                    : mpchange < 0
                    ? "bg-[#372929] text-[#C83D4D]"
                    : "bg-[#252932] text-[#7A8597]"
            } ${className}`}
        >
            <div className="cursor-pointer text-xs font-normal uppercase">
                <SymbolLabel Symbol={asset.symbol?.toUpperCase()} />
            </div>
            <p className="text-xs font-normal">{mpchange?.toFixed(2)}%</p>
            {mpchange > 0 ? (
                <MemoGrowth className="h-[6px] w-3" />
            ) : mpchange < 0 ? (
                <MemoDecline className="h-[6px] w-3" />
            ) : (
                <MemoGrowth className="h-[6px] w-3" color={"bg-[#252932]"} />
            )}
        </div>
    );
}

export function NegativeBadge({
    asset,
    value,
    className,
}: {
    asset?: string;
    value?: string | number;
    className?: string;
}) {
    return (
        <div
            className={`flex w-full items-center justify-center gap-1 rounded-2xl bg-[#372929] px-2 py-1 text-[#C83D4D] ${className}`}
        >
            <p className="text-xs font-normal uppercase">{asset}</p>
            <p className="text-xs font-normal">{value}</p>
            <MemoDecline className="h-[6px] w-3" />
        </div>
    );
}

export function SmallPositiveBadge({ value, className }: { value?: string | number; className?: string }) {
    return (
        <div
            className={`flex w-full items-center justify-center gap-1 rounded-2xl bg-[#20342F] px-1 py-1 text-[#27AF8F] ${className}`}
        >
            <p className="text-xs font-normal">{value}</p>
            <MemoGrowth className="h-[6px] w-3" color="#27AF8F" />
        </div>
    );
}

export function SmallNegativeBadge({ value, className }: { value?: string | number; className?: string }) {
    return (
        <div
            className={`flex w-full items-center justify-center gap-1 rounded-2xl bg-[#372929] px-1 py-1 text-[#C83D4D] ${className}`}
        >
            <p className="text-xs font-normal">{value || "-2.81%"}</p>
            <MemoDecline className="h-[6px] w-3" />
        </div>
    );
}

export function SmallDailyChangeBadge({ value, className }: { value?: string | number; className?: string }) {
    if (Number(value) >= 0) {
        return (
            <div
                className={`flex items-center justify-center gap-1 rounded-2xl bg-[#20342F] p-0 px-1.5 text-[#27AF8F] ${className}`}
            >
                <p className="text-xs font-medium">+{`${value}%` || "+3.34%"}</p>
                <MemoGrowth className="h-3 w-3" color="#27AF8F" />
            </div>
        );
    } else {
        return (
            <div
                className={`flex items-center justify-center gap-1 rounded-2xl bg-[#372929] p-0 px-1.5 text-[#C83D4D] ${className}`}
            >
                <p className="text-xs font-medium">{`${value}%` || "-2.81%"}</p>
                <MemoDecline className="h-3 w-3" />
            </div>
        );
    }
}

export function SentimentBadge({ value }: { value: string }) {
    return (
        <>
            {value === "positive" ? (
                <div className="flex h-4 w-6 items-center justify-center gap-2 rounded-lg bg-[#20342F] px-1 py-0">
                    <MemoSmilingEmoji className="h-3 w-3" />
                </div>
            ) : value === "negative" ? (
                <div className="flex h-4 w-6 items-center justify-center gap-2 rounded-lg bg-[#372929] px-1 py-0">
                    <MemoFrowningEmoji className="h-3 w-3" />
                </div>
            ) : (
                <div className="flex h-4 w-6 items-center justify-center gap-2 rounded-lg bg-[#373529] px-1 py-0">
                    <MemoNeutralEmoji className="h-3 w-3" />
                </div>
            )}
        </>
    );
}
