import { proxy } from "valtio";

export interface SearchPayload {
    total: number;
    from: number;
    assets: string[];
    headline: string;
    sources: string[];
    time: number[];
    tags: string[];
    filterForLast7Days: boolean;
    connector: string;
    primarySourcesOnly: boolean;
}

export const defaultSearch: SearchPayload = {
    total: 0,
    from: 0,
    assets: [],
    headline: "",
    sources: [],
    time: [],
    tags: [],
    filterForLast7Days: false,
    connector: "",
    primarySourcesOnly: false,
};
export const searchProxy = proxy({ ...defaultSearch });

export const clearSearchProxy = () => {
    Object.keys(defaultSearch).forEach((k) => {
        searchProxy[k] = defaultSearch[k];
        // the below is an hack - the above didn't clear the sources array all the time.
        searchProxy.sources = [];
        searchProxy.tags = [];
        searchProxy.assets = [];
        searchProxy.time = [];
        searchProxy.connector = "";
        searchProxy.primarySourcesOnly = false;
    });

    clearOutOfContextProxy();
};

export const clearOutOfContextProxy = () => {
    outOfContextProxy.tag = "";
    outOfContextProxy.category = "";
};

export const outOfContextProxy = proxy({
    tag: "",
    category: "",
});
