import { useEffect, useState } from "react";
import { Observable, Subject, Subscription } from "rxjs";
import { MiniChart } from "../MiniChart/MiniChart";
import ReactDOM from "react-dom";

export interface MiniChartTooltipProps {
    Symbol?: string;
    Width?: number;
    Height?: number;
    X?: number;
    Y?: number;
    Visible: boolean;
}

export class MiniChartTooltipServiceClass {
    public symbolSubject: Subject<string> = new Subject<string>();
    globalCurrentSymbol: string = "";

    setCurrentSymbol(symbol) {
        if (this.globalCurrentSymbol != symbol) {
            this.globalCurrentSymbol = symbol;
            this.symbolSubject.next(symbol);
        }
    }
    clearSymbol() {
        this.globalCurrentSymbol = "";
        this.symbolSubject.next("");
    }
    getCurrentSymbol(): Observable<string> {
        return this.symbolSubject.asObservable();
    }
}

export const MiniChartTooltipService: MiniChartTooltipServiceClass = new MiniChartTooltipServiceClass();

interface SubHolder {
    Sub: Subscription | undefined;
}

export function MiniChartTooltip({
    Symbol = "",
    X = 0,
    Y = 0,
    Width = 300,
    Height = 200,
}: MiniChartTooltipProps) {
    const [symbol, setSymbol] = useState<string>(Symbol);
    //const [currentGlobalSymbol, setCurrentGlobalSymbol] = useState<string>(Symbol);
    const [width, setWidth] = useState<number>(Width);
    const [height, setHeight] = useState<number>(Height);
    const [internalPosY, setInternalPosY] = useState<number>(Y);
    const [internalPosX, setInternalPosX] = useState<number>(X);

    const [posX, setPosX] = useState<number>(X);
    const [posY, setPosY] = useState<number>(Y);
    const [canRender, setCanRender] = useState<boolean>(false);
    const [sub, setSub] = useState<SubHolder>({ Sub: undefined });
    useEffect(() => {
        if (!sub.Sub) {
            const tmpSub = MiniChartTooltipService.getCurrentSymbol().subscribe((s) => {
                const tmp = s == symbol;
                if (tmp != canRender) {
                    setCanRender(tmp);
                }
            });
            setSub({ Sub: tmpSub });
            MiniChartTooltipService.setCurrentSymbol(symbol);
        }
        const other = document.querySelectorAll<HTMLDivElement>(".minichart-tooltip");
        if (other.length > 0) {
            other.forEach((e) => {
                e.style.display = "none";
            });
        }
        const h = window.getComputedStyle(document.body).getPropertyValue("Height");
        const w = window.getComputedStyle(document.body).getPropertyValue("Width");
        const computedHeight = parseInt(h.substring(0, h.length - 2));
        const computedWidth = parseInt(w.substring(0, w.length - 2));
        const computedPositionY = posY + height > computedHeight ? computedHeight - height - 10 : posY;
        const computedPositionX = posX + width > computedWidth ? computedWidth - width - 10 : posX;
        setInternalPosY(computedPositionY);
        setInternalPosX(computedPositionX);

        return () => {
            if (sub.Sub) {
                sub.Sub.unsubscribe();
                if (MiniChartTooltipService.globalCurrentSymbol == symbol) {
                    MiniChartTooltipService.clearSymbol();
                }
                sub.Sub = undefined;
            }
        };
    }, []);

    return ReactDOM.createPortal(
        <>
            {canRender && (
                <div
                    className="minichart-tooltip"
                    style={{
                        position: "fixed",
                        top: internalPosY,
                        left: internalPosX,
                        zIndex: 100000,
                        display: "block",
                        width: `${width}px`,
                        height: `${height}px`,
                        pointerEvents: "none",
                    }}
                >
                    <MiniChart Symbol={symbol}></MiniChart>
                </div>
            )}
        </>,
        document.getElementsByTagName("body")[0],
        "minichart-tooltip",
    );
}
