import { BBApiClient, HistoryResponse, MiniChartData } from "./TSMarketApi";

export const APIURL = process.env.NEXT_PUBLIC_BASE_URL_MARKET_API
    ? process.env.NEXT_PUBLIC_BASE_URL_MARKET_API
    : "https://api.blockbeat.io";

export function getBBApiClient(): BBApiClient {
    return new BBApiClient(APIURL);
}

export async function fetchSymbolHistory(symbol: string): Promise<HistoryResponse> {
    const apiCLient = getBBApiClient();
    return apiCLient.getSymbolHistorySimple(symbol);
}

export async function fetchSymbolMiniChartData(
    symbol: string,
    range: string,
    interval: string,
): Promise<MiniChartData> {
    const apiCLient = getBBApiClient();
    return apiCLient.getSymbolMiniChartData(symbol, range, interval);
}
