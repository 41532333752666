import { SVGProps, memo } from "react";

function Mail(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 12" fill="none" {...props}>
            <path
                d="M.834 1.933L8.331 5.68l7.497-3.748A1.875 1.875 0 0013.956.167H2.706A1.875 1.875 0 00.834 1.933z"
                fill={props.color || "#7A869B"}
            />
            <path
                d="M15.832 4.027l-7.5 3.75-7.5-3.75v5.515a1.875 1.875 0 001.875 1.875h11.25a1.875 1.875 0 001.875-1.875V4.027z"
                fill={props.color || "#7A869B"}
            />
        </svg>
    );
}

const MemoMail = memo(Mail);
export default MemoMail;
