// bookmarkedListState.js
import { proxy } from "valtio";

const bookmarkedListState = proxy({
    data: [] as string[],
    isLoading: false,
    error: null,
});

export default bookmarkedListState;
