import { SVGProps, memo } from "react";

function RedditBare(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 14" fill="none" {...props}>
            <path
                d="M13.602 4.959c-.295 0-.562.08-.803.187a.77.77 0 01-.721-.027c-.803-.427-1.738-.721-2.728-.882-.454-.053-.748-.508-.641-.962l.4-1.685c.027-.08.08-.134.161-.107l1.524.348a.759.759 0 01.562.534 1.52 1.52 0 001.417.963c.829 0 1.497-.668 1.497-1.497 0-.83-.668-1.498-1.497-1.498-.428 0-.802.16-1.07.455a.86.86 0 01-.775.24L8.949.575a.404.404 0 00-.481.267l-.562 2.3c-.133.561-.615.99-1.176 1.043-1.096.133-2.112.427-2.968.882-.214.107-.481.134-.695.027a1.945 1.945 0 00-.776-.187C1.302 4.906.5 5.708.5 6.697c0 .481.187.936.508 1.257.16.16.24.347.24.561v.08c0 1.23.723 2.38 2.033 3.209 1.256.802 2.914 1.257 4.679 1.257 1.765 0 3.422-.455 4.679-1.257 1.31-.856 2.032-1.979 2.032-3.209V8.57c0-.214.08-.428.268-.589.347-.32.561-.775.561-1.283-.107-.936-.91-1.738-1.898-1.738zM4.698 7.767c0-.589.508-1.07 1.096-1.07.588 0 1.07.481 1.07 1.07 0 .588-.482 1.069-1.07 1.069a1.079 1.079 0 01-1.096-1.07zm5.668 3.02c-.508.509-1.337.776-2.46.776s-1.898-.24-2.433-.748a.442.442 0 01-.08-.535.404.404 0 01.642-.08c.347.347.962.534 1.871.534.91 0 1.524-.16 1.872-.534a.42.42 0 01.588 0c.16.187.16.428 0 .588zm-.32-1.951c-.589 0-1.097-.481-1.097-1.07 0-.588.508-1.069 1.097-1.069.588 0 1.069.481 1.069 1.07 0 .588-.481 1.069-1.07 1.069z"
                fill={props.color || "#7A869B"}
            />
        </svg>
    );
}

const MemoRedditBare = memo(RedditBare);
export default MemoRedditBare;
